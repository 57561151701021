<template>
  <v-container>
    <TitleGeneral textTitle="Clinical Scenarios" style="margin-bottom: 12px" />

    <v-row class="text-center" style="margin-bottom: 12px">
      <v-col cols="12" style="padding: 0px 12px">
        <p class="parrafo-g" style="font-size: 20px">
          There are clinical scenarios that require more detailed consideration
          between the prescriber and the patient. Below are a select few such
          scenarios.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" style="align-items: center; justify-content: center">
        <div
          style="
            background-color: #f2f2f2;
            padding-right: 12px;
            padding-top: 12px;
            border-radius: 0 30px 20px 20px;
            position: relative;
          "
        >
          <v-btn
            icon
            dark
            @click="buttonDesplegable"
            style="position: absolute; top: 12px; right: 12px; z-index: 1"
          >
            <div
              style="
                background-color: #131b70;
                width: 60px;
                height: 60px;
                border-radius: 50%;
              "
            >
              <v-img
                :src="require(`../assets/${imagen}`)"
                width="36px"
                height="36px"
                style="position: absolute; right: 12px; top: 12px"
              />
            </div>
          </v-btn>

          <div>
            <div
              class="divClass"
              @click="buttonDesplegable"
              style="
                background-color: white;
                width: 100%;
                height: 112px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 20px;
                position: relative;
                cursor: pointer;
              "
            >
              <p
                style="
                  color: #4c5358;
                  font-size: 20px;
                  font-family: Poppins;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 28px;
                  text-transform: uppercase;
                  margin-bottom: 0px;
                "
              >
                Manic and depressive symptoms simultaneously
              </p>
            </div>
          </div>

          <v-expand-transition>
            <div class="desplegable" v-show="value">
              <p>
                Mood stabilizers such as Valproate and Atypical Antipsychotics
                can treat manic and depressive symptoms presented simultaneously
              </p>
              <p>Antidepressants may increase this mixed symptoms profile</p>
            </div>
          </v-expand-transition>

          <div class="mx-4 hidden-sm-and-down"></div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" style="align-items: center; justify-content: center">
        <div
          style="
            background-color: #f2f2f2;
            padding-right: 12px;
            padding-top: 12px;
            border-radius: 0 30px 20px 20px;
            position: relative;
          "
        >
          <v-btn
            icon
            dark
            @click="buttonDesplegable1"
            style="position: absolute; top: 12px; right: 12px; z-index: 1"
          >
            <div
              style="
                background-color: #131b70;
                width: 60px;
                height: 60px;
                border-radius: 50%;
              "
            >
              <v-img
                :src="require(`../assets/${imagen1}`)"
                width="36px"
                height="36px"
                style="position: absolute; right: 12px; top: 12px"
              />
            </div>
          </v-btn>

          <div>
            <div
              class="divClass"
              @click="buttonDesplegable1"
              style="
                background-color: white;
                width: 100%;
                height: 112px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 20px;
                position: relative;
                cursor: pointer;
              "
            >
              <p
                style="
                  color: #4c5358;
                  font-size: 20px;
                  font-family: Poppins;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 28px;
                  text-transform: uppercase;
                  margin-bottom: 0px;
                "
              >
                No current treatment vs On Antidepressants or Mood stabilizers
              </p>
            </div>
          </div>

          <v-expand-transition>
            <div class="desplegable" v-show="value1">
              <p>Avoid antidepressant monotherapy in Bipolar 1</p>
              <p>Antidepressants:</p>

              <ul>
                <li>
                  Antidepressants monotherapy could be an option for Bipolar
                  type 2 AD non rapid cycling.
                </li>
                <li>
                  There may be a greater benefit combining antidepressants with
                  antipsychotic.
                </li>
              </ul>

              <p style="margin-top: 16px">Mood stabilizer or antipsychotic:</p>

              <ul>
                <li>
                  Consider an adjunctive antidepressant or second mood
                  stabilizer.
                </li>
              </ul>
            </div>
          </v-expand-transition>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" style="align-items: center; justify-content: center">
        <div
          style="
            background-color: #f2f2f2;
            padding-right: 12px;
            padding-top: 12px;
            border-radius: 0 30px 20px 20px;
            position: relative;
          "
        >
          <v-btn
            icon
            dark
            @click="buttonDesplegable2"
            style="position: absolute; top: 12px; right: 12px; z-index: 1"
          >
            <div
              style="
                background-color: #131b70;
                width: 60px;
                height: 60px;
                border-radius: 50%;
              "
            >
              <v-img
                :src="require(`../assets/${imagen2}`)"
                width="36px"
                height="36px"
                style="position: absolute; right: 12px; top: 12px"
              />
            </div>
          </v-btn>

          <div>
            <div
              class="divClass"
              @click="buttonDesplegable2"
              style="
                background-color: white;
                width: 100%;
                height: 112px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 20px;
                position: relative;
                cursor: pointer;
              "
            >
              <p
                style="
                  color: #4c5358;
                  font-size: 20px;
                  font-family: Poppins;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 28px;
                  text-transform: uppercase;
                  margin-bottom: 0px;
                "
              >
                History of mania (bipolar type 1) or hypomania (bipolar type 2)
              </p>
            </div>
          </div>

          <v-expand-transition>
            <div class="desplegable" v-show="value2">
              <p>
                Avoid antidepressant monotherapy if there is history of mania
                (bipolar type 1)
              </p>
            </div>
          </v-expand-transition>
        </div>
      </v-col>
    </v-row>

    <!--         <v-row>
            <v-col v-for="(itemOutcome, index) in itemsOutcomes" :key="index" cols="4"
                style="display: flex;align-items: center;justify-content: center;">

                <div
                    style="background-color: #F2F2F2;padding-right: 12px;padding-top: 12px;border-radius: 0 30px 20px 20px;position: relative;">

                    <div
                        style="background-color: #131B70;width: 60px;height: 60px;border-radius: 50%;position: absolute;top: 0;right: 0;z-index: 1;">
                        <v-img :src="require(`../assets/outcomes/${itemOutcome.icon}`)"
                            style="position: absolute;right: 10px;top: 10px;" />
                    </div>

                    <div>
                        <div class="divClass"
                            style="background-color: white;width: 298px;height: 169px;margin: auto;display: flex;align-items: center;justify-content: space-between;padding: 0 20px;position: relative;">
                            <p
                                style="color: #4C5358;font-size: 20px;font-family: Poppins;font-style: normal;font-weight: 600;line-height: 28px;text-transform: uppercase;margin-bottom: 0px;">
                                {{ itemOutcome.title }}
                            </p>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row> -->
  </v-container>
</template>

<style>
.desplegable p {
  color: #131b70;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.desplegable {
  padding: 25px 25px 0 25px;
}

.desplegable ul li {
  color: #4c5358;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.4px;
}

.desplegable ul {
  list-style: none;
}

.desplegable ul li:before {
  content: "\2022";
  color: #b88542;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -25px;
}
</style>

<script>
import TitleGeneral from "./repetitive/TitleGeneral.vue";

export default {
  name: "Intervention",
  components: {
    TitleGeneral,
  },
  data: () => ({
    imagen: "flechaaba.svg",
    imagen1: "flechaaba.svg",
    imagen2: "flechaaba.svg",
    value: false,
    value1: false,
    value2: false,
    btnVerified: false,
    btnVerified1: false,
    btnVerified2: false,
  }),
  methods: {
    buttonDesplegable: function () {
      this.btnVerified = !this.btnVerified;

      if (this.btnVerified == true) {
        this.imagen = "fechaar.svg";
        this.value = true;

        /* prueba */
        this.value1 = false;
        this.value2 = false;
        this.imagen1 = "flechaaba.svg";
        this.imagen2 = "flechaaba.svg";
        this.btnVerified1 = false;
        this.btnVerified2 = false;
      } else {
        this.imagen = "flechaaba.svg";
        this.value = false;
      }
    },
    buttonDesplegable1: function () {
      this.btnVerified1 = !this.btnVerified1;

      if (this.btnVerified1 == true) {
        this.imagen1 = "fechaar.svg";
        this.value1 = true;

        /* prueba */
        this.value = false;
        this.value2 = false;
        this.imagen = "flechaaba.svg";
        this.imagen2 = "flechaaba.svg";
        this.btnVerified = false;
        this.btnVerified2 = false;
      } else {
        this.imagen1 = "flechaaba.svg";
        this.value1 = false;
      }
    },
    buttonDesplegable2: function () {
      this.btnVerified2 = !this.btnVerified2;

      if (this.btnVerified2 == true) {
        this.imagen2 = "fechaar.svg";
        this.value2 = true;

        /* prueba */
        this.value = false;
        this.value1 = false;
        this.imagen = "flechaaba.svg";
        this.imagen1 = "flechaaba.svg";
        this.btnVerified = false;
        this.btnVerified1 = false;
      } else {
        this.imagen2 = "flechaaba.svg";
        this.value2 = false;
      }
    },
  },
};
</script>
