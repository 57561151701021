<template>
  <v-container :style="{ height: '100%', position: 'relative' }">
    <v-row class="text-center">
      <TitleGeneral textTitle="Welcome" />

      <v-col cols="12">
        <v-img
          :src="require('../assets/welcome.svg')"
          class="my-3"
          contain
          height="364"
          style="background-size: cover !important"
        />
        <p
          style="
            text-align: left;
            color: #4c5358;
            font-size: 14px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0px;
          "
        >
          Photo by Marek Piwnicki
        </p>
      </v-col>

      <v-col cols="12" class="mb-4">
        <p class="welcome-p">
          Bipolar disorder is a treatable mental disorder characterized by mood
          changes including mania, hypomania, and depression.
        </p>
        <p class="welcome-p">
          Episodes of bipolar depression tend to last longer than
          manic/hypomanic episodes, occur more frequently, and are more
          difficult to treat.
        </p>
        <p class="welcome-p">
          This conversation aid is intended to guide selection of treatment for
          bipolar depression.
        </p>
      </v-col>

      <v-col cols="12" style="text-align: left">
        <v-dialog v-model="dialog" width="auto">
          <template v-slot:activator="{ on, clinician }">
            <v-btn
              class="welcome-button"
              style="color: #faf4f2; background: #131b70; padding: 25px 40px"
              v-bind="clinician"
              v-on="on"
            >
              FOR THE CLINICIAN
            </v-btn>
          </template>

          <div
            style="
              background: transparent;
              padding-right: 12px;
              padding-top: 16px;
              border-radius: 0 30px 20px 20px;
              position: relative;
            "
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
              style="position: absolute; top: 12px; right: 12px; z-index: 1"
            >
              <div
                style="
                  background-color: #131b70;
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                "
              >
                <v-img
                  :src="require(`../assets/cerrar.svg`)"
                  style="position: absolute; left: 10px; top: 10px"
                />
              </div>
            </v-btn>

            <v-card style="padding: 84px 95px; position: relative">
              <v-card-title
                class="title-card"
                style="font-weight: 600 !important"
              >
                Introductory instructions for the clinician
              </v-card-title>

              <ul class="list-ul-dialog">
                <li>
                  This resource is intended to guide discussion in the setting
                  of a clinical consultation.
                </li>
                <li>
                  Presents the level of clinical evidence for the treatments
                  according to guidelines and existing literature for bipolar
                  depression.
                </li>
                <li>
                  Presents a comparison of the most common side effects,
                  monitoring need and average relative cost of the treatments.
                </li>
                <li>
                  Several clinical scenarios are presented that could have an
                  impact in the discussion for treatment selection.
                </li>
                <li>
                  This guide should not be used in persons with high suicide
                  risk and/or psychotic symptoms.
                </li>
              </ul>
            </v-card>
          </div>
        </v-dialog>

        <v-dialog v-model="dialogp" width="auto">
          <template v-slot:activator="{ on, patient }">
            <v-btn
              class="welcome-button"
              style="
                color: #faf4f2;
                background: #131b70;
                padding: 25px 40px;
                opacity: 0.7;
              "
              v-bind="patient"
              v-on="on"
            >
              FOR THE PATIENT
            </v-btn>
          </template>

          <div
            style="
              background: transparent;
              padding-right: 12px;
              padding-top: 16px;
              border-radius: 0 30px 20px 20px;
              position: relative;
            "
          >
            <v-btn
              icon
              dark
              @click="dialogp = false"
              style="position: absolute; top: 12px; right: 12px; z-index: 1"
            >
              <div
                style="
                  background-color: #131b70;
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                "
              >
                <v-img
                  :src="require(`../assets/cerrar.svg`)"
                  style="position: absolute; left: 10px; top: 10px"
                />
              </div>
            </v-btn>

            <v-card style="padding: 84px 95px; position: relative">
              <v-card-title class="title-card">
                Introductory instructions for the PATIENT
              </v-card-title>

              <ul class="list-ul-dialog">
                <li>
                  This resource should be used with the guidance of your
                  provider while discussing treatment options for bipolar
                  depression.
                </li>
                <li>
                  Many individuals’ choices are influenced by their particular
                  clinical situation, current treatment, potential cost, or side
                  effects.
                </li>
                <li>
                  Some treatment selections, such as psychotherapy or brain
                  stimulation, may require a referral to a different provider to
                  receive.
                </li>
                <li>
                  We encourage you to consider, throughout the shared
                  decision-making process, your treatment priorities and what
                  questions you may have.
                </li>
              </ul>
            </v-card>
          </div>
        </v-dialog>
      </v-col>
    </v-row>

    <v-divider :style="{ margin: '24px 0px' }"></v-divider>

    <p
      style="
        color: #4c5358;
        font-size: 12px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.48px;
      "
    >
      <span style="text-decoration-line: underline"> Privacy policy</span>
      (Updated October, 2014) Terms and conditions of use policy (Updated
      February 6, 2015). LEGAL CONDITIONS AND TERMS OF USE APPLICABLE TO ALL
      USERS OF THIS SITE. ANY USE OF THIS SITE CONSTITUTES YOUR AGREEMENT TO
      THESE TERMS AND CONDITIONS OF USE.
    </p>
    <p
      style="
        color: #4c5358;
        font-size: 12px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      "
    >
      1998-2022 Mayo Foundation for Medical Education and Research. All rights
      reserved.
    </p>
  </v-container>
</template>

<style>
.welcome-p {
  color: #4c5358;
  font-size: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 28px;
  text-align: left;
}

.welcome-button {
  border-radius: 44px;
  text-align: center;
  font-size: 13.75px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 20.625px;
  letter-spacing: 0.344px;
  padding: 25px 40px;
  margin-right: 40px;
}

.title-card {
  color: #131b70;
  text-align: center;
  font-size: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
  margin-bottom: 38px;
  justify-content: center;
}

.v-sheet.v-card {
  border-radius: 0 32px 20px 20px !important;
}

.list-ul-dialog {
  color: #4c5358;
  font-size: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.5px;
}

.list-ul-dialog li {
  margin-bottom: 20px;
}

.v-image__image--contain {
  background-size: cover;
}

.v-application .mb-4 {
  margin-bottom: 0px !important;
}

.v-dialog {
  margin: 100px;
  box-shadow: none;
}

.v-dialog > .v-card > .v-card__title {
  font-weight: 600 !important;
}

.v-dialog > * {
  width: 1094px;
}

.theme--light.v-divider {
  border-color: #4c5358;
  opacity: 0.6000000238418579;
}
</style>

<script>
import TitleGeneral from "./repetitive/TitleGeneral.vue";

export default {
  name: "HelloWorld",
  components: {
    TitleGeneral,
  },
  data: () => ({
    dialog: false,
    dialogp: false,
  }),
};
</script>
