<template>
    <v-row class="text-center">
        <v-col cols="12" style="padding: 0px 12px;">
            <h3 class="welcome-h3">
                {{ textTitle }}
            </h3>
        </v-col>
    </v-row>
</template>

<style scoped>
.welcome-h3 {
    color: #455D89;
    font-size: 32px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    line-height: 48px;
    text-align: left;
}
</style>

<script>
export default {
    name: 'TitleGeneral',
    props: {
        textTitle: String,
    },
    data: () => ({
    })
}
</script>