<template>
  <v-container>
    <TitleGeneral textTitle="Warnings" style="margin-bottom: 12px" />
    <v-row class="text-center" style="margin-bottom: 12px">
      <!--  12+16 = 28 -->
      <v-col cols="12" style="padding: 0px 12px">
        <p class="parrafo-g">
          Uncommon, but potentially serious, side effects that could occur with
          use of the medication. Hover over the warning to see details relevant
          to each medication.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="text-align: left; padding-bottom: 4px"
      ></v-col>
      <v-col
        cols="12"
        md="9"
        lg="10"
        style="
          text-align: right;
          padding-bottom: 0;
          display: flex;
          justify-content: space-between;
        "
      >
        <div style="width: 50%"></div>
        <div style="width: 50%; display: flex">
          <div
            style="
              width: 65%;
              margin-right: 4px;
              border-radius: 10px 10px 0px 0px;
              background: #131b70;
              color: #faf4f2;
              text-align: center;
              font-family: Poppins;
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 15px;
              letter-spacing: 0.25px;
              text-transform: uppercase;
              padding: 10px 36px;
            "
          >
            blackbox warning
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- EMPIEZA TODO RESPECTO AL BODY -->
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          padding-bottom: 0;
          padding-top: 0;
        "
      >
        <div>
          <div
            class="divClass_1"
            style="
              background-color: white;
              width: 298px;
              height: 185px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 20px;
              position: relative;
            "
          >
            <p
              style="
                color: #4c5358;
                font-size: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 0px;
              "
            >
              Antipsychotics
            </p>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="9" lg="10" class="divClass_2_padre">
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Cariprazine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Increased death rate in elderly patients who have a history of dementia and psychosis."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Lumateperone
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Increased death rate in elderly patients who have a history of dementia and psychosis."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Lurasidone
              <MenuIcono menuTitle="Should be taken with food" />
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Increased death rate in elderly patients who have a history of dementia and psychosis."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Olanzapine/Fluoxetine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Increased death rate in elderly patients who have a history of dementia and psychosis."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Quetiapine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Increased death rate in elderly patients who have a history of dementia and psychosis."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          padding-bottom: 0;
          padding-top: 0;
        "
      >
        <div>
          <div
            class="divClass_1"
            style="
              background-color: white;
              width: 298px;
              height: 111px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 20px;
              position: relative;
            "
          >
            <p
              style="
                color: #4c5358;
                font-size: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 0px;
              "
            >
              Mood stabilizers
            </p>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="9" lg="10" class="divClass_2_padre">
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Lamotrigine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Significant skin rash may occur."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Lithium
              <MenuIcono
                menuTitle="Can cause thyroid and kidney problems."
                menuTitle1="Requires blood monitoring for serum levels."
                menuTitle2="If you are pregnant, tell you doctor."
              />
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Lithium toxicity is closely related to serum lithium concentrations,
                                            and can occur at doses close to therapeutic concentrations."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Valproic Acid
              <MenuIcono
                menuTitle="Can cause problems with your liver or pancreas."
                menuTitle1="Requires blood monitoring for serum levels."
                menuTitle2="Valproic acid should be avoided during pregnancy. Pregnancy protection is
                                                   required for women of childbearing age."
              />
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="May cause hepatotoxicity or pancreatitis. During pregnancy, usage can cause major congenital malformations, particularly neural tube defects."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          padding-bottom: 0;
          padding-top: 0;
        "
      >
        <div>
          <div
            class="divClass_1"
            style="
              background-color: white;
              width: 298px;
              height: 222px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 20px;
              position: relative;
            "
          >
            <p
              style="
                color: #4c5358;
                font-size: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 0px;
              "
            >
              Antidepressants
            </p>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="9" lg="10" class="divClass_2_padre">
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Bupropion
              <MenuIcono menuTitle="Higher risk of seizures." />
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Usage for children and adolescents may increase the risk of suicidality."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Escitalopram
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Usage for children and adolescents may increase the risk of suicidality."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Fluoxetine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Usage for children and adolescents may increase the risk of suicidality."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Paroxetine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Usage for children and adolescents may increase the risk of suicidality."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Sertraline
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Usage for children and adolescents may increase the risk of suicidality."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Venlafaxine
              <MenuIcono
                menuTitle="Tell your doctor if you have high blood pressure."
              />
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="Usage for children and adolescents may increase the risk of suicidality."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          padding-bottom: 0;
          padding-top: 0;
        "
      >
        <div>
          <div
            class="divClass_1"
            style="
              background-color: white;
              width: 298px;
              height: 74px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 20px;
              position: relative;
            "
          >
            <p
              style="
                color: #4c5358;
                font-size: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 0px;
              "
            >
              NOVEL
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="9" lg="10" class="divClass_2_padre">
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Ketamine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <MenuIcono
              menuTitleParrafo="May cause hemodynamic emergence reaction."
              validationWarning="Warning"
            />
          </div>

          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Modafinil/Armodafinil
            </p>
          </div>
          <div style="display: flex; align-items: center; width: 35%"></div>
          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          padding-bottom: 0;
          padding-top: 0;
        "
      >
        <div>
          <div
            class="divClass_1"
            style="
              background-color: white;
              width: 298px;
              height: 74px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 20px;
              position: relative;
            "
          >
            <p
              style="
                color: #4c5358;
                font-size: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 0px;
              "
            >
              stimulation
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="9" lg="10" class="divClass_2_padre">
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              ECT
            </p>
          </div>
          <div style="display: flex; align-items: center; width: 35%"></div>
          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              TMS
            </p>
          </div>
          <div style="display: flex; align-items: center; width: 35%"></div>
          <div
            class="div-interior-0"
            style="display: flex; align-items: center; width: 15%"
          ></div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          padding-bottom: 0;
          padding-top: 0;
        "
      >
        <div>
          <div
            class="divClass_1"
            style="
              background-color: white;
              width: 298px;
              height: 111px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 20px;
              position: relative;
            "
          >
            <p
              style="
                color: #4c5358;
                font-size: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 0px;
              "
            >
              Psychotherapy
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="9" lg="10" class="divClass_2_padre">
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              CBT
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <div
              class="div-button-not-aplicable"
              style="
                border-radius: 32px;
                background: #f2f2f2;
                color: #65594a;
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0.25px;
                padding: 9px 90px 8px 91px;
                height: 32px;
              "
            >
              NOT APLICABLE
            </div>
          </div>
          <div class="div-interior-0" :style="{ width: '15%' }"></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              FFT
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <div
              class="div-button-not-aplicable"
              style="
                border-radius: 32px;
                background: #f2f2f2;
                color: #65594a;
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0.25px;
                padding: 9px 90px 8px 91px;
                height: 32px;
              "
            >
              NOT APLICABLE
            </div>
          </div>
          <div class="div-interior-0" :style="{ width: '15%' }"></div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              IPSRT
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: center;
            "
          >
            <div
              class="div-button-not-aplicable"
              style="
                border-radius: 32px;
                background: #f2f2f2;
                color: #65594a;
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0.25px;
                padding: 9px 90px 8px 91px;
                height: 32px;
              "
            >
              NOT APLICABLE
            </div>
          </div>
          <div class="div-interior-0" :style="{ width: '15%' }"></div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.divClass_1 {
  border-radius: 0 20px 20px 0;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
  border: 1px solid #dccec1;
}

.parrafo-g {
  color: #4c5358;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.p-col9 {
  color: #65594a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  border-bottom: 1px solid #f2f2f2;
  /* MOMENTANEO*/
}

.divClass_2 {
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  height: 37px;
}

.title-card-1 {
  color: #4c5358;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-transform: uppercase;
}
</style>

<script>
import MenuIcono from "./repetitive/MenuIcono.vue";
import TitleGeneral from "./repetitive/TitleGeneral.vue";

export default {
  name: "Warning",
  components: {
    MenuIcono,
    TitleGeneral,
  },
  data: () => ({
    dialogTableKey: false,
    btnVerified: true,
    itemsClinicalEvidence: [
      { title: "Antipsychotics" },
      { title: "Mood stabilizers" },
      { title: "Antidepressants" },
      { title: "NOVEL" },
      { title: "stimulation" },
      {
        title: "Psychotherapy",
        itemsEvidence: [
          {
            titleEvidence: "CBT",
            imagenBD1: "",
            imagenBD2: "",
            imagenBD3: "",
          },
        ],
      },
    ],
  }),
};
</script>
