<template>
  <v-container>
    <TitleGeneral textTitle="Link to referrals" style="margin-bottom: 54px" />

    <v-list style="background-color: #f2f2f2">
      <v-list-item v-for="link in links" :key="link.title">
        <v-list-item-icon>
          <v-image class="mr-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle cx="12" cy="12" r="12" fill="#B88542" />
            </svg>
          </v-image>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            v-text="link.title"
            style="
              color: var(--mayoclinic-gray, #4c5358);
              font-family: Poppins;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 140% */
            "
          ></v-list-item-title>
          <v-list-item-subtitle
            v-text="link.link"
            style="
              color: #15c;
              font-family: Poppins;
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 140% */
            "
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- <ul class="custom-list">
      <li v-for="(link, index) in links" class="px-3">
        <div class="">{{ link.title }}</div>
        <span class="ml-1">
          <a class="link-a" href=" https://www.dbsalliance.org" target="_blank">
            {{ link.link }}</a
          >
        </span>
      </li>
    </ul> -->

    <!-- <v-row
      class="px-3"
      v-for="(link, index) in links"
      :key="index"
      style="margin-bottom: 52px"
    >
      <v-image class="mr-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <circle cx="12" cy="12" r="12" fill="#B88542" />
        </svg>
      </v-image>

      <div class="link-referral" style="font-size: 16px; margin: 0">
        {{ link.title }}
        <span class="ml-1">
          <a class="link-a" href=" https://www.dbsalliance.org" target="_blank">
            {{ link.link }}</a
          >
        </span>
      </div>
    </v-row> -->

    <!-- <v-row>
            <v-col v-for="(itemTreatment, index) in itemsTreatment" :key="index" cols="12" md="6" lg="4">
                <div>
                    <div class="divClass"
                        style="background-color: #B88542;width: 277px;height: 202px;margin: auto;display: flex;align-items: center;justify-content: space-between;padding: 0 20px;position: relative;">
                    </div>
                </div>

                <p class="text-link">{{ itemTreatment.title }}</p>

            </v-col>
        </v-row> -->
  </v-container>
</template>

<script>
import MenuIcono from "./repetitive/MenuIcono.vue";
import TitleGeneral from "./repetitive/TitleGeneral.vue";

export default {
  name: "Intervention",
  components: {
    MenuIcono,
    TitleGeneral,
  },
  data: () => ({
    links: [
      {
        title: "Depression and Bipolar SupporT Alliance (DBSA).",
        link: "https://www.dbsalliance.org",
      },
      {
        title: "National Alliance on Mental Illness (NAMI).",
        link: "https://www.nami.org/Support-Education/Support-Groups",
      },
      {
        title: "Mayo Clinic Mood clinic.",
        link: "https://www.mayoclinic.org/diseases-conditions/mood-disorders/care-at-mayo-clinic/mac-20365060",
      },
    ],
    itemsTreatment: [
      { title: "Bipolar Disorder" },
      { title: "Bipolar Disorder" },
      { title: "Bipolar Disorder" },
      { title: "Bipolar Disorder" },
      { title: "Bipolar Disorder" },
      { title: "Bipolar Disorder" },
    ],
  }),
};
</script>

<style scoped>
ul {
  list-style-image: url("../assets/list_item.svg");
}

li {
  vertical-align: center !important;
}

ul li::marker {
  color: red;
  font-size: 1.5em;
  text-align: center !important;
  text-align-last: center !important;

  vertical-align: center !important;
}

/* ul li {
  background: url("../assets/list_item.svg") no-repeat left center;
  padding: 5px 10px 5px 25px;
  list-style: none;
  margin: 0;
  vertical-align: text-top;
} */

/* li {
  padding-bottom: 30px !important;
} */
.link-a {
  color: #15c !important;
}
.link-referral {
  color: #4c5358;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.text-link {
  color: #4c5358;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  line-height: 28px;
  text-transform: uppercase;
  margin-top: 36px;
  margin-bottom: 56px;
}
</style>
