<template>
    <v-menu v-model="menu" open-on-hover :close-on-content-click="false" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="v-menu-btn" icon v-bind="attrs" v-on="on" :style="{'display':validationButtonDisplay}">
                <v-img v-if="validationWarning == ''" :src="require('../../assets/info.svg')"/>
                <v-img v-else :src="require('../../assets/blackbox.svg')" :style="{'margin-right': '24px'}"/>
            </v-btn>
        </template>

        <div class="v-menu-div-general">
            <div>
                <v-img v-if="validationWarning == ''" class="v-menu-div-general-v-img" 
                       :src="require(`../../assets/infoa.svg`)" />
                <v-img v-else class="v-menu-div-general-v-img" 
                       :src="require(`../../assets/blackboxa.svg`)" />
            </div>

            <v-card class="v-menu-div-general-v-card">
                <p v-if="menuTitleParrafo != ''" class="parrafoValidation">{{ menuTitleParrafo }}</p>

                <ul v-else>
                    <li v-if="menuTitle != ''">
                        <p>{{ menuTitle }}</p>
                    </li>
                    <li v-if="menuTitle1 != ''">
                        <p>{{ menuTitle1 }}</p>
                    </li>
                    <li v-if="menuTitle2 != ''">
                        <p>{{ menuTitle2 }}</p>
                    </li>
                </ul>
            </v-card>
        </div>
    </v-menu>
</template>

<style scoped>
.v-menu__content {
    box-shadow: none !important;
}

.v-menu-btn {
    width: 24px;
    height: 24px;
}

.v-menu-div-general {
    background: transparent;
    padding-right: 12px;
    padding-top: 16px;
    border-radius: 0 30px 20px 20px;
    position: relative;
}

.v-menu-div-general div:first-child {
    background-color: #B88542;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.v-menu-div-general-v-img {
    position: absolute !important;
    width: 40px !important;
    height: 40px !important;
    margin: 10px !important;
}

.v-menu-div-general-v-card {
    padding: 32px 24px;
    width: 298px;
    background-color: #131B70;
    color: #FAF4F2;
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.v-menu-div-general-v-card ul li:first-child {
    padding-right: 52px;
}

.parrafoValidation {
    padding-right: 52px;
    margin-bottom: 0px !important;
}

.v-menu-div-general-v-card ul li:last-child p {
    margin-bottom: 0px;
}
</style>

<script>
export default {
    name: 'MenuIcono',
    props: {
        menuTitleParrafo: {
            type: String,
            default: ""
        },
        menuTitle: {
            type: String,
            default: ""
        },
        menuTitle1: {
            type: String,
            default: ""
        },
        menuTitle2: {
            type: String,
            default: ""
        },
        validationWarning: {
            type: String,
            default: ""
        },
        validationButtonDisplay: {
            type: String,
            default: ""
        }
    },
    data: () => ({
    })
}
</script>