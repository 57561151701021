import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Intervention from "../components/Intervention.vue";
import Outcome from "../components/Outcome.vue";
import ClinicalScenario from "../components/ClinicalScenario.vue";
import TreatmentNetwork from "../components/TreatmentNetwork.vue";
import Glossary from "../components/Glossary.vue";
import ClinicalEvidence from "../components/ClinicalEvidence.vue";
import LaboratoryMonitoring from "../components/LaboratoryMonitoring.vue";
import Warning from "../components/Warning.vue";
import WeightAgain from "../components/WeightAgain.vue";
import Sedation from "../components/Sedation.vue";
import SexualDysfunction from "../components/SexualDysfunction.vue";
import Tremor from "../components/Tremor.vue";
import Cognition from "../components/Cognition.vue";
import ManicSwitch from "../components/ManicSwitch.vue";
import TimeToResponse from "../components/TimeToResponse.vue";
import Cost from "../components/Cost.vue";
import PerinatalConcern from "../components/PerinatalConcern.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/interventions",
    name: "interventions",
    component: Intervention,
  },
  {
    path: "/outcome",
    name: "outcomes",
    component: Outcome,
  },
  {
    path: "/clinical-scenarios",
    name: "clinical-scenarios",
    component: ClinicalScenario,
  },
  {
    path: "/treatment-networks",
    name: "treatment-networks",
    component: TreatmentNetwork,
  },
  // {
  //   path: '/glossary',
  //   name: 'glossary',
  //   component: Glossary
  // },
  {
    path: "/clinical-evidence",
    name: "clinical-evidence",
    component: ClinicalEvidence,
  },
  {
    path: "/laboratory-monitoring",
    name: "laboratory-monitoring",
    component: LaboratoryMonitoring,
  },
  {
    path: "/warning",
    name: "warning",
    component: Warning,
  },
  {
    path: "/weight-again",
    name: "weight-again",
    component: WeightAgain,
  },
  {
    path: "/sedation",
    name: "sedation",
    component: Sedation,
  },
  {
    path: "/sexual-dysfunction",
    name: "sexual-dysfunction",
    component: SexualDysfunction,
  },
  {
    path: "/tremor",
    name: "tremor",
    component: Tremor,
  },
  {
    path: "/cognition",
    name: "cognition",
    component: Cognition,
  },
  {
    path: "/manic-switch",
    name: "manic-switch",
    component: ManicSwitch,
  },
  {
    path: "/time-to-response",
    name: "time-to-response",
    component: TimeToResponse,
  },
  {
    path: "/cost",
    name: "cost",
    component: Cost,
  },
  {
    path: "/perinatal-concern",
    name: "perinatal-concern",
    component: PerinatalConcern,
  },
];

const router = new VueRouter({
  mode: "",
  base: process.env.BASE_URL,
  routes,
});

export default router;
