<template>
  <v-container>
    <TitleGeneral textTitle="Outcomes" style="margin-bottom: 12px" />

    <v-row class="text-center" style="margin-bottom: 12px">
      <v-col cols="12" style="padding: 0px 12px">
        <p class="parrafo-g" style="font-size: 20px">
          Potential outcomes treatments to consider. Below are the most common
          factors of interest for patients, providers, and caretakers.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(itemOutcome, index) in itemsOutcomes"
        :key="index"
        cols="12"
        md="6"
        lg="4"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div
          style="
            background-color: #f2f2f2;
            padding-right: 12px;
            padding-top: 12px;
            border-radius: 0 30px 20px 20px;
            position: relative;
          "
        >
          <div
            style="
              background-color: #131b70;
              width: 60px;
              height: 60px;
              border-radius: 50%;
              position: absolute;
              top: 0;
              right: 0;
              z-index: 1;
            "
          >
            <v-img
              :src="require(`../assets/outcomes/${itemOutcome.icon}`)"
              style="position: absolute; right: 10px; top: 10px"
            />
          </div>

          <div>
            <div
              class="divClass"
              @click="redirigirPaginasOutcomes(itemOutcome.action)"
              style="
                cursor: pointer;
                background-color: white;
                width: 298px;
                height: 169px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                position: relative;
              "
            >
              <p
                style="
                  color: #4c5358;
                  font-size: 20px;
                  font-family: Poppins;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 28px;
                  text-transform: uppercase;
                  margin-bottom: 0px;
                "
              >
                {{ itemOutcome.title }}
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.v-list-item {
  padding: 12px 36px;
}

.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 30px;
}

.divClass {
  border-radius: 0 30px 20px 20px;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
}

.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: #65594a;
}

.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal;
}
</style>

<script>
import TitleGeneral from "./repetitive/TitleGeneral.vue";
import MenuIcono from "./repetitive/MenuIcono.vue";
import App from "../App.vue";

export default {
  name: "Outcome",
  components: {
    MenuIcono,
    TitleGeneral,
    App,
  },
  components: {
    TitleGeneral,
  },
  data: () => ({
    itemsOutcomes: [
      {
        title: "Clinical evidence BD I and II",
        icon: "clinical.svg",
        action: "clinical-evidence",
      },
      {
        title: "Laboratory monitoring",
        icon: "laboratory.svg",
        action: "laboratory-monitoring",
      },
      { title: "Warnings", icon: "warning.svg", action: "warning" },
      { title: "Weight gain", icon: "weight.svg", action: "weight-again" },
      { title: "Sedation", icon: "sedation.svg", action: "sedation" },
      {
        title: "Sexual dysfunction",
        icon: "sexual.svg",
        action: "sexual-dysfunction",
      },
      { title: "Tremors", icon: "tremor.svg", action: "tremor" },
      { title: "Cognition", icon: "cognition.svg", action: "cognition" },
      {
        title: "Perinatal concerns",
        icon: "perinatal.svg",
        action: "perinatal-concern",
      },
      { title: "Manic switch", icon: "manic.svg", action: "manic-switch" },
      {
        title: "Time-to-response",
        icon: "time.svg",
        action: "time-to-response",
      },
      { title: "Cost", icon: "cost.svg", action: "cost" },
    ],
  }),
  methods: {
    redirigirPaginasOutcomes(action) {
      this.$router.push(action);
    },
  },
};
</script>
