<template>
  <v-container v-on:click="select" :style="{ height: '100%' }">
    <TitleGeneral
      textTitle="Interventions Options"
      style="margin-bottom: 12px"
    />

    <v-row class="text-center" style="margin-bottom: 12px">
      <v-col cols="12" style="padding: 0px 12px">
        <p class="parrafo-g" style="font-size: 20px">
          Below are examples of common intervention options available for
          treatment of Bipolar Depression, listed by category. Considerations
          for each intervention can be found under “Outcomes”. Additional
          intervention options may be available through your provider.
        </p>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col
        cols="12"
        md="6"
        lg="4"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div
          style="
            background-color: #f2f2f2;
            padding-right: 12px;
            padding-top: 16px;
            border-radius: 0 30px 20px 20px;
            position: relative;
          "
        >
          <div
            style="
              background-color: #131b70;
              width: 60px;
              height: 60px;
              border-radius: 50%;
              position: absolute;
              top: 0;
              right: 0;
              z-index: 1;
            "
          >
            <v-img
              :src="require(`../assets/interventiona.svg`)"
              style="position: absolute; right: 10px; top: 10px"
            />
          </div>
          <div class="text-center">
            <v-menu
              offset-y
              style="box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15) !important"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="divClass"
                  ref="myDivClass"
                  style="
                    background-color: white;
                    width: 298px;
                    height: 169px;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px;
                    position: relative;
                    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
                  "
                >
                  <p
                    style="
                      color: #4c5358;
                      font-size: 20px;
                      font-family: Poppins;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 28px;
                      text-transform: uppercase;
                      margin-bottom: 0px;
                    "
                  >
                    Antipsychotics
                  </p>
                  <v-btn icon v-bind="attrs" v-on="on" v-on:click="btnImage">
                    <v-img
                      :src="require(`../assets/${imagen}`)"
                      width="24"
                      height="24"
                    />
                  </v-btn>
                </div>
              </template>
              <v-list class="v-list-intervention">
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title
                    >Cariprazine</v-list-item-title
                  ></v-list-item
                >
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title
                    >Lumateperone</v-list-item-title
                  ></v-list-item
                >
                <v-list-item class="v-list-item-intervention">
                  <v-list-item-title icon>Lurasidone</v-list-item-title>
                  <MenuIcono menuTitle="Should be taken with food" />
                </v-list-item>
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title
                    >Olanzapine/Fluoxetine</v-list-item-title
                  ></v-list-item
                >
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title
                    >Quetiapine</v-list-item-title
                  ></v-list-item
                >
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="4"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div
          style="
            background-color: #f2f2f2;
            padding-right: 12px;
            padding-top: 16px;
            border-radius: 0 30px 20px 20px;
            position: relative;
          "
        >
          <div
            style="
              background-color: #131b70;
              width: 60px;
              height: 60px;
              border-radius: 50%;
              position: absolute;
              top: 0;
              right: 0;
              z-index: 1;
            "
          >
            <v-img
              :src="require(`../assets/interventiona.svg`)"
              style="position: absolute; right: 10px; top: 10px"
            />
          </div>
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="divClass"
                  ref="myDivClass1"
                  style="
                    background-color: white;
                    width: 298px;
                    height: 169px;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px;
                    position: relative;
                    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
                  "
                >
                  <p
                    style="
                      color: #4c5358;
                      font-size: 20px;
                      font-family: Poppins;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 28px;
                      text-transform: uppercase;
                      margin-bottom: 0px;
                    "
                  >
                    Mood stabilizers
                  </p>
                  <v-btn icon v-bind="attrs" v-on="on" v-on:click="btnImage1">
                    <v-img
                      :src="require(`../assets/${imagen1}`)"
                      width="24"
                      height="24"
                    />
                  </v-btn>
                </div>
              </template>
              <v-list class="v-list-intervention">
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title
                    >Lamotrigine</v-list-item-title
                  ></v-list-item
                >
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title icon>Lithium</v-list-item-title>
                  <MenuIcono
                    menuTitle="Can cause thyroid and kidney problems."
                    menuTitle1="Requires blood monitoring for serum levels."
                    menuTitle2="If you are pregnant, tell you doctor."
                  />
                </v-list-item>
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title>Valproic Acid</v-list-item-title>
                  <MenuIcono
                    menuTitle="Can cause problems with your liver or pancreas."
                    menuTitle1="Requires blood monitoring for serum levels."
                    menuTitle2="Valproic acid should be avoided during pregnancy. Pregnancy protection is
                                                   required for women of childbearing age."
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="4"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div
          style="
            background-color: #f2f2f2;
            padding-right: 12px;
            padding-top: 16px;
            border-radius: 0 30px 20px 20px;
            position: relative;
          "
        >
          <div
            style="
              background-color: #131b70;
              width: 60px;
              height: 60px;
              border-radius: 50%;
              position: absolute;
              top: 0;
              right: 0;
              z-index: 1;
            "
          >
            <v-img
              :src="require(`../assets/interventiona.svg`)"
              style="position: absolute; right: 10px; top: 10px"
            />
          </div>
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="divClass"
                  ref="myDivClass2"
                  style="
                    background-color: white;
                    width: 298px;
                    height: 169px;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px;
                    position: relative;
                    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
                  "
                >
                  <p
                    style="
                      color: #4c5358;
                      font-size: 20px;
                      font-family: Poppins;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 28px;
                      text-transform: uppercase;
                      margin-bottom: 0px;
                    "
                  >
                    Antidepressants
                  </p>
                  <v-btn icon v-bind="attrs" v-on="on" v-on:click="btnImage2">
                    <v-img
                      :src="require(`../assets/${imagen2}`)"
                      width="24"
                      height="24"
                    />
                  </v-btn>
                </div>
              </template>
              <v-list class="v-list-intervention">
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title>Bupropion</v-list-item-title>
                  <MenuIcono menuTitle="Higher risk of seizures." />
                </v-list-item>
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title icon
                    >Escitalopram</v-list-item-title
                  ></v-list-item
                >
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title
                    >Fluoxetine</v-list-item-title
                  ></v-list-item
                >
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title
                    >Paroxetine</v-list-item-title
                  ></v-list-item
                >
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title
                    >Sertraline</v-list-item-title
                  ></v-list-item
                >
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title>Venlafaxine</v-list-item-title>
                  <MenuIcono
                    menuTitle="Tell your doctor if you have high blood pressure."
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="4"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div
          style="
            background-color: #f2f2f2;
            padding-right: 12px;
            padding-top: 16px;
            border-radius: 0 30px 20px 20px;
            position: relative;
          "
        >
          <div
            style="
              background-color: #131b70;
              width: 60px;
              height: 60px;
              border-radius: 50%;
              position: absolute;
              top: 0;
              right: 0;
              z-index: 1;
            "
          >
            <v-img
              :src="require(`../assets/brain.svg`)"
              style="position: absolute; right: 10px; top: 10px"
            />
          </div>

          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="divClass"
                  ref="myDivClass3"
                  style="
                    background-color: white;
                    width: 298px;
                    height: 169px;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px;
                    position: relative;
                    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
                  "
                >
                  <p
                    style="
                      color: #4c5358;
                      font-size: 20px;
                      font-family: Poppins;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 28px;
                      text-transform: uppercase;
                      margin-bottom: 0px;
                    "
                  >
                    Brain stimulation
                  </p>
                  <v-btn icon v-bind="attrs" v-on="on" v-on:click="btnImage3">
                    <v-img
                      :src="require(`../assets/${imagen3}`)"
                      width="24"
                      height="24"
                    />
                  </v-btn>
                </div>
              </template>
              <v-list class="v-list-intervention">
                <v-list-item
                  class="v-list-item-intervention"
                  style="padding: 12px 36px !important"
                  ><v-list-item-title icon
                    >Electro Convulsive Therapy (ECT)</v-list-item-title
                  ></v-list-item
                >
                <v-list-item
                  class="v-list-item-intervention"
                  style="padding: 12px 36px !important"
                  ><v-list-item-title
                    >Transcranial Magnetic Stimulation (TMS)</v-list-item-title
                  ></v-list-item
                >
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="4"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div
          style="
            background-color: #f2f2f2;
            padding-right: 12px;
            padding-top: 16px;
            border-radius: 0 30px 20px 20px;
            position: relative;
          "
        >
          <div
            style="
              background-color: #131b70;
              width: 60px;
              height: 60px;
              border-radius: 50%;
              position: absolute;
              top: 0;
              right: 0;
              z-index: 1;
            "
          >
            <v-img
              :src="require(`../assets/cognitive.svg`)"
              style="position: absolute; right: 10px; top: 10px"
            />
          </div>
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="divClass"
                  ref="myDivClass4"
                  style="
                    background-color: white;
                    width: 298px;
                    height: 169px;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px;
                    position: relative;
                    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
                  "
                >
                  <p
                    style="
                      color: #4c5358;
                      font-size: 20px;
                      font-family: Poppins;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 28px;
                      text-transform: uppercase;
                      margin-bottom: 0px;
                    "
                  >
                    Psychotherapy
                  </p>
                  <v-btn icon v-bind="attrs" v-on="on" v-on:click="btnImage4">
                    <v-img
                      :src="require(`../assets/${imagen4}`)"
                      width="24"
                      height="24"
                    />
                  </v-btn>
                </div>
              </template>
              <v-list class="v-list-intervention">
                <v-list-item
                  class="v-list-item-intervention"
                  style="padding: 12px 36px !important"
                  ><v-list-item-title
                    >Cognitive Behavioral Therapy (CBT)</v-list-item-title
                  ></v-list-item
                >
                <v-list-item
                  class="v-list-item-intervention"
                  style="padding: 12px 36px !important"
                  ><v-list-item-title
                    >Family Focused Therapy (FFT)</v-list-item-title
                  ></v-list-item
                >
                <v-list-item
                  class="v-list-item-intervention"
                  style="padding: 12px 36px !important"
                  ><v-list-item-title
                    >Interpersonal and Social Rhythm Therapy
                    (IPSRT)</v-list-item-title
                  ></v-list-item
                >
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="4"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div
          style="
            background-color: #f2f2f2;
            padding-right: 12px;
            padding-top: 16px;
            border-radius: 0 30px 20px 20px;
            position: relative;
          "
        >
          <div
            style="
              background-color: #131b70;
              width: 60px;
              height: 60px;
              border-radius: 50%;
              position: absolute;
              top: 0;
              right: 0;
              z-index: 1;
            "
          >
            <v-img
              :src="require(`../assets/estrella.svg`)"
              style="position: absolute; right: 10px; top: 10px"
            />
          </div>

          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="divClass"
                  ref="myDivClass5"
                  style="
                    background-color: white;
                    width: 298px;
                    height: 169px;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px;
                    position: relative;
                    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
                  "
                >
                  <p
                    style="
                      color: #4c5358;
                      font-size: 20px;
                      font-family: Poppins;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 28px;
                      text-transform: uppercase;
                      margin-bottom: 0px;
                    "
                  >
                    Novel
                  </p>
                  <v-btn icon v-bind="attrs" v-on="on" v-on:click="btnImage5">
                    <v-img
                      :src="require(`../assets/${imagen5}`)"
                      width="24"
                      height="24"
                    />
                  </v-btn>
                </div>
              </template>
              <v-list class="v-list-intervention">
                <v-list-item class="v-list-item-intervention"
                  ><v-list-item-title
                    >Rapid acting: Ketamine</v-list-item-title
                  ></v-list-item
                >
                <v-list-item
                  class="v-list-item-intervention"
                  style="padding: 12px 36px !important"
                  ><v-list-item-title
                    >Stimulant act compounds:
                    Modafinil/Armodafinil</v-list-item-title
                  ></v-list-item
                >
                <v-list-item
                  class="v-list-item-intervention"
                  style="padding: 12px 36px !important"
                  ><v-list-item-title
                    >Nutritional: Dietary Interventions</v-list-item-title
                  ></v-list-item
                >
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleGeneral from "./repetitive/TitleGeneral.vue";
import MenuIcono from "./repetitive/MenuIcono.vue";

export default {
  name: "Intervention",
  components: {
    MenuIcono,
    TitleGeneral,
  },
  data: () => ({
    imagen: "fechaab.svg",
    imagen1: "fechaab.svg",
    imagen2: "fechaab.svg",
    imagen3: "fechaab.svg",
    imagen4: "fechaab.svg",
    imagen5: "fechaab.svg",
    btnVerified: true,
    btnVerified1: true,
    btnVerified2: true,
    btnVerified3: true,
    btnVerified4: true,
    btnVerified5: true,
    itemsInterventions: [
      { title: "Antipsychotics" },
      { title: "Mood stabilizers" },
      { title: "Antidepressants" },
      { title: "Brain stimulation" },
      { title: "Psychotherapy" },
      { title: "Novel" },
    ],
  }),
  methods: {
    /* REFACTORIZAR */
    btnImage() {
      /* VALIDACIONES GENERALES - INICIO */
      this.imagen1 = "fechaab.svg";
      this.imagen2 = "fechaab.svg";
      this.imagen3 = "fechaab.svg";
      this.imagen4 = "fechaab.svg";
      this.imagen5 = "fechaab.svg";
      this.$refs.myDivClass1.classList.add("divClass");
      this.$refs.myDivClass2.classList.add("divClass");
      this.$refs.myDivClass3.classList.add("divClass");
      this.$refs.myDivClass4.classList.add("divClass");
      this.$refs.myDivClass5.classList.add("divClass");
      this.btnVerified1 = true;
      this.btnVerified2 = true;
      this.btnVerified3 = true;
      this.btnVerified4 = true;
      this.btnVerified5 = true;
      /* VALIDACIONES GENERALES - FINAL */

      this.btnVerified = !this.btnVerified;
      if (this.btnVerified == true) {
        this.imagen = "fechaab.svg";
        this.$refs.myDivClass.classList.add("divClass");
      } else {
        this.imagen = "fechaar.svg";
        this.$refs.myDivClass.classList.remove("divClass");
      }
    },
    btnImage1() {
      /* VALIDACIONES GENERALES - INICIO */
      this.imagen = "fechaab.svg";
      this.imagen2 = "fechaab.svg";
      this.imagen3 = "fechaab.svg";
      this.imagen4 = "fechaab.svg";
      this.imagen5 = "fechaab.svg";
      this.$refs.myDivClass.classList.add("divClass");
      this.$refs.myDivClass2.classList.add("divClass");
      this.$refs.myDivClass3.classList.add("divClass");
      this.$refs.myDivClass4.classList.add("divClass");
      this.$refs.myDivClass5.classList.add("divClass");
      this.btnVerified = true;
      this.btnVerified2 = true;
      this.btnVerified3 = true;
      this.btnVerified4 = true;
      this.btnVerified5 = true;
      /* VALIDACIONES GENERALES - FINAL */

      this.btnVerified1 = !this.btnVerified1;
      if (this.btnVerified1 == true) {
        this.imagen1 = "fechaab.svg";
        this.$refs.myDivClass1.classList.add("divClass");
      } else {
        this.imagen1 = "fechaar.svg";
        this.$refs.myDivClass1.classList.remove("divClass");
      }
    },
    btnImage2() {
      /* VALIDACIONES GENERALES - INICIO */
      this.imagen = "fechaab.svg";
      this.imagen1 = "fechaab.svg";
      this.imagen3 = "fechaab.svg";
      this.imagen4 = "fechaab.svg";
      this.imagen5 = "fechaab.svg";
      this.$refs.myDivClass.classList.add("divClass");
      this.$refs.myDivClass1.classList.add("divClass");
      this.$refs.myDivClass3.classList.add("divClass");
      this.$refs.myDivClass4.classList.add("divClass");
      this.$refs.myDivClass5.classList.add("divClass");
      this.btnVerified = true;
      this.btnVerified1 = true;
      this.btnVerified3 = true;
      this.btnVerified4 = true;
      this.btnVerified5 = true;
      /* VALIDACIONES GENERALES - FINAL */

      this.btnVerified2 = !this.btnVerified2;
      if (this.btnVerified2 == true) {
        this.imagen2 = "fechaab.svg";
        this.$refs.myDivClass2.classList.add("divClass");
      } else {
        this.imagen2 = "fechaar.svg";
        this.$refs.myDivClass2.classList.remove("divClass");
      }
    },
    btnImage3() {
      /* VALIDACIONES GENERALES - INICIO */
      this.imagen = "fechaab.svg";
      this.imagen1 = "fechaab.svg";
      this.imagen2 = "fechaab.svg";
      this.imagen4 = "fechaab.svg";
      this.imagen5 = "fechaab.svg";
      this.$refs.myDivClass.classList.add("divClass");
      this.$refs.myDivClass1.classList.add("divClass");
      this.$refs.myDivClass2.classList.add("divClass");
      this.$refs.myDivClass4.classList.add("divClass");
      this.$refs.myDivClass5.classList.add("divClass");
      this.btnVerified = true;
      this.btnVerified1 = true;
      this.btnVerified2 = true;
      this.btnVerified4 = true;
      this.btnVerified5 = true;
      /* VALIDACIONES GENERALES - FINAL */

      this.btnVerified3 = !this.btnVerified3;
      if (this.btnVerified3 == true) {
        this.imagen3 = "fechaab.svg";
        this.$refs.myDivClass3.classList.add("divClass");
      } else {
        this.imagen3 = "fechaar.svg";
        this.$refs.myDivClass3.classList.remove("divClass");
      }
    },
    btnImage4() {
      /* VALIDACIONES GENERALES - INICIO */
      this.imagen = "fechaab.svg";
      this.imagen1 = "fechaab.svg";
      this.imagen2 = "fechaab.svg";
      this.imagen3 = "fechaab.svg";
      this.imagen5 = "fechaab.svg";
      this.$refs.myDivClass.classList.add("divClass");
      this.$refs.myDivClass1.classList.add("divClass");
      this.$refs.myDivClass2.classList.add("divClass");
      this.$refs.myDivClass3.classList.add("divClass");
      this.$refs.myDivClass5.classList.add("divClass");
      this.btnVerified = true;
      this.btnVerified1 = true;
      this.btnVerified2 = true;
      this.btnVerified3 = true;
      this.btnVerified5 = true;
      /* VALIDACIONES GENERALES - FINAL */

      this.btnVerified4 = !this.btnVerified4;
      if (this.btnVerified4 == true) {
        this.imagen4 = "fechaab.svg";
        this.$refs.myDivClass4.classList.add("divClass");
      } else {
        this.imagen4 = "fechaar.svg";
        this.$refs.myDivClass4.classList.remove("divClass");
      }
    },
    btnImage5() {
      /* VALIDACIONES GENERALES - INICIO */
      this.imagen = "fechaab.svg";
      this.imagen1 = "fechaab.svg";
      this.imagen2 = "fechaab.svg";
      this.imagen3 = "fechaab.svg";
      this.imagen4 = "fechaab.svg";
      this.$refs.myDivClass.classList.add("divClass");
      this.$refs.myDivClass1.classList.add("divClass");
      this.$refs.myDivClass2.classList.add("divClass");
      this.$refs.myDivClass3.classList.add("divClass");
      this.$refs.myDivClass4.classList.add("divClass");
      this.btnVerified = true;
      this.btnVerified1 = true;
      this.btnVerified2 = true;
      this.btnVerified3 = true;
      this.btnVerified4 = true;
      /* VALIDACIONES GENERALES - FINAL */

      this.btnVerified5 = !this.btnVerified5;
      if (this.btnVerified5 == true) {
        this.imagen5 = "fechaab.svg";
        this.$refs.myDivClass5.classList.add("divClass");
      } else {
        this.imagen5 = "fechaar.svg";
        this.$refs.myDivClass5.classList.remove("divClass");
      }
    },
    select: function () {
      this.imagen = "fechaab.svg";
      this.imagen1 = "fechaab.svg";
      this.imagen2 = "fechaab.svg";
      this.imagen3 = "fechaab.svg";
      this.imagen4 = "fechaab.svg";
      this.imagen5 = "fechaab.svg";
      this.$refs.myDivClass.classList.add("divClass");
      this.$refs.myDivClass1.classList.add("divClass");
      this.$refs.myDivClass2.classList.add("divClass");
      this.$refs.myDivClass3.classList.add("divClass");
      this.$refs.myDivClass4.classList.add("divClass");
      this.$refs.myDivClass5.classList.add("divClass");
      this.btnVerified = true;
      this.btnVerified1 = true;
      this.btnVerified2 = true;
      this.btnVerified3 = true;
      this.btnVerified4 = true;
      this.btnVerified5 = true;
    },
  },
};
</script>

<style scoped>
.v-menu__content {
  border-radius: 0 0 20px 20px;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
}

.v-menu__content .v-list {
  padding: 0px;
}

.v-list-item-intervention {
  padding: 0 36px !important;
  border-bottom: 1px solid #dccec1 !important;
}

.v-list-item-intervention:last-child {
  border-bottom: 0px solid white !important;
}

.v-menu__content .v-list .v-list-item:first-child {
  border-top: 1px solid #dccec1;
}

.v-menu__content .v-list .v-list-item:last-child {
  border-bottom: 0px solid #dccec1;
}

.v-menu__content .v-list .v-list-item {
  border-bottom: 1px solid #455d89;
  color: #65594a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 30px;
}

.divClass {
  border-radius: 0 30px 20px 20px;
}

.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: #65594a;
}

.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal;
}

.v-list-intervention {
  width: 298px;
}
</style>
