<template>
    <v-container>
        <TitleGeneral textTitle="Glossary"/>
    </v-container>
</template>

<script>
import TitleGeneral from "./repetitive/TitleGeneral.vue";

export default {
    name: 'Glossary',
    components: {
        TitleGeneral
    },
    data: () => ({
    })
}
</script>