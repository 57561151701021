<template>
  <v-container>
    <TitleGeneral textTitle="Perinatal Concerns" style="margin-bottom: 12px" />

    <v-row class="text-center" style="margin-bottom: 12px">
      <v-col cols="12" style="padding: 0px 12px">
        <p class="parrafo-g">
          In pregnancy or breastfeeding it is important to carefully review
          treatment options with your provider. Click on the appropriate tab to
          see relative frequency and severity of perinatal concerns. Please
          refer to Table Key for more detail on relative risk.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="text-align: left; padding-bottom: 4px"
      >
        <v-dialog v-model="dialogTableKey" width="auto">
          <template v-slot:activator="{ on, tableKey }">
            <v-btn
              class="welcome-button"
              style="
                padding: 6px 36px;
                border-radius: 10px 10px 0px 0px;
                background: #b88542;
                color: #faf4f2;
                text-align: center;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.3px;
              "
              v-bind="tableKey"
              v-on="on"
            >
              TABLE KEY
            </v-btn>
          </template>

          <div
            style="
              background: transparent;
              padding-right: 12px;
              padding-top: 16px;
              border-radius: 0 30px 20px 20px;
              position: relative;
              width: 368px;
            "
          >
            <v-btn
              icon
              dark
              @click="dialogTableKey = false"
              style="position: absolute; top: 12px; right: 12px; z-index: 1"
            >
              <div
                style="
                  background-color: #131b70;
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                "
              >
                <v-img
                  :src="require(`../assets/cerrar.svg`)"
                  style="position: absolute; left: 10px; top: 10px"
                />
              </div>
            </v-btn>

            <v-card style="position: relative">
              <v-card-title
                class="title-card-1"
                style="padding: 24px 0 24px 28px; border: 1px solid #dccec1"
              >
                Perinatal Concerns
              </v-card-title>

              <v-row style="margin: 0; border-bottom: 1px solid #dccec1">
                <v-col
                  cols="5"
                  style="padding: 16px 28px; border-right: 1px solid #dccec1"
                >
                  <v-img
                    :src="require(`../assets/less.svg`)"
                    width="32"
                    height="32"
                    style="flex: none; margin-right: 24px"
                  />
                </v-col>
                <v-col cols="7" style="padding: 0">
                  <p
                    style="
                      margin-bottom: 0;
                      color: #65594a;
                      font-family: Poppins;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 24px;
                      padding: 20px 0px 20px 28px;
                    "
                  >
                    No concern
                  </p>
                </v-col>
              </v-row>

              <v-row style="margin: 0; border-bottom: 1px solid #dccec1">
                <v-col
                  cols="5"
                  style="padding: 16px 28px; border-right: 1px solid #dccec1"
                >
                  <v-img
                    :src="require(`../assets/validation2.svg`)"
                    width="32"
                    height="32"
                    style="flex: none; margin-right: 24px"
                  />
                </v-col>
                <v-col cols="7" style="padding: 0">
                  <p
                    style="
                      margin-bottom: 0;
                      color: #65594a;
                      font-family: Poppins;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 24px;
                      padding: 20px 0px 20px 28px;
                    "
                  >
                    Low concern
                  </p>
                </v-col>
              </v-row>

              <v-row style="margin: 0; border-bottom: 1px solid #dccec1">
                <v-col
                  cols="5"
                  style="
                    padding: 16px 28px;
                    border-right: 1px solid #dccec1;
                    display: flex;
                  "
                >
                  <v-img
                    :src="require(`../assets/validation2.svg`)"
                    width="32"
                    height="32"
                    style="flex: none; margin-right: 8px"
                  />
                  <v-img
                    :src="require(`../assets/validation2.svg`)"
                    width="32"
                    height="32"
                    style="flex: none"
                  />
                </v-col>
                <v-col cols="7" style="padding: 0">
                  <p
                    style="
                      margin-bottom: 0;
                      color: #65594a;
                      font-family: Poppins;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 24px;
                      padding: 20px 0px 20px 28px;
                    "
                  >
                    Moderate concern
                  </p>
                </v-col>
              </v-row>

              <v-row style="margin: 0; border-bottom: 1px solid #dccec1">
                <v-col
                  cols="5"
                  style="
                    padding: 16px 28px;
                    border-right: 1px solid #dccec1;
                    display: flex;
                  "
                >
                  <v-img
                    :src="require(`../assets/validation2.svg`)"
                    width="32"
                    height="32"
                    style="flex: none; margin-right: 8px"
                  />
                  <v-img
                    :src="require(`../assets/validation2.svg`)"
                    width="32"
                    height="32"
                    style="flex: none; margin-right: 8px"
                  />
                  <v-img
                    :src="require(`../assets/validation2.svg`)"
                    width="32"
                    height="32"
                    style="flex: none"
                  />
                </v-col>
                <v-col cols="7" style="padding: 0">
                  <p
                    style="
                      margin-bottom: 0;
                      color: #65594a;
                      font-family: Poppins;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 24px;
                      padding: 20px 0px 20px 28px;
                    "
                  >
                    High concern
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-dialog>
      </v-col>
      <v-col
        cols="12"
        md="9"
        lg="10"
        style="
          text-align: right;
          padding-bottom: 0;
          display: flex;
          justify-content: space-between;
        "
      >
        <div style="width: 50%"></div>
        <div style="width: 50%; display: flex">
          <div
            class="buttonTab1_css"
            @click="activeColorButtonTab"
            :style="{ 'background-color': colorButtonTab }"
          >
            pregnancy
          </div>
          <div
            class="buttonTab2_css"
            @click="activeColorButtonTab1"
            :style="{ 'background-color': colorButtonTab1 }"
          >
            breastfeeding
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- EMPIEZA TODO RESPECTO AL BODY -->
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          padding-bottom: 0;
          padding-top: 0;
        "
      >
        <div>
          <div
            class="divClass_1"
            style="
              background-color: white;
              width: 298px;
              height: 185px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 20px;
              position: relative;
            "
          >
            <p
              style="
                color: #4c5358;
                font-size: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 0px;
              "
            >
              Antipsychotics
            </p>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="9" lg="10" class="divClass_2_padre">
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Cariprazine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 12px"
            />
            <div class="div_no_data" :style="{ padding: '9px 60px 8px 61px' }">
              NO DATA
            </div>
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-left: 12px"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Lumateperone
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 12px"
            />
            <div class="div_no_data" :style="{ padding: '9px 60px 8px 61px' }">
              NO DATA
            </div>
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-left: 12px"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Lurasidone
              <MenuIcono menuTitle="Should be taken with food" />
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 12px"
            />
            <div class="div_no_data" :style="{ padding: '9px 60px 8px 61px' }">
              NO DATA
            </div>
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-left: 12px"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Olanzapine/Fluoxetine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Quetiapine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          padding-bottom: 0;
          padding-top: 0;
        "
      >
        <div>
          <div
            class="divClass_1"
            style="
              background-color: white;
              width: 298px;
              height: 111px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 20px;
              position: relative;
            "
          >
            <p
              style="
                color: #4c5358;
                font-size: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 0px;
              "
            >
              Mood stabilizers
            </p>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="9" lg="10" class="divClass_2_padre">
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Lamotrigine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Lithium
              <MenuIcono
                menuTitle="Can cause thyroid and kidney problems."
                menuTitle1="Requires blood monitoring for serum levels."
                menuTitle2="If you are pregnant, tell you doctor."
              />
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Valproic Acid
              <MenuIcono
                menuTitle="Can cause problems with your liver or pancreas."
                menuTitle1="Requires blood monitoring for serum levels."
                menuTitle2="Valproic acid should be avoided during pregnancy. Pregnancy protection is
                                                   required for women of childbearing age."
              />
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/${nameImageCircle}.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/${nameImageCircle}.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          padding-bottom: 0;
          padding-top: 0;
        "
      >
        <div>
          <div
            class="divClass_1"
            style="
              background-color: white;
              width: 298px;
              height: 222px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 20px;
              position: relative;
            "
          >
            <p
              style="
                color: #4c5358;
                font-size: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 0px;
              "
            >
              Antidepressants
            </p>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="9" lg="10" class="divClass_2_padre">
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Bupropion
              <MenuIcono menuTitle="Higher risk of seizures." />
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Escitalopram
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Fluoxetine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Paroxetine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/${nameImageLess}.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/${nameImageCircle}.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Sertraline
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/${nameImageLess}.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/${nameImageCircle}.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Venlafaxine
              <MenuIcono
                menuTitle="Tell your doctor if you have high blood pressure."
              />
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          padding-bottom: 0;
          padding-top: 0;
        "
      >
        <div>
          <div
            class="divClass_1"
            style="
              background-color: white;
              width: 298px;
              height: 74px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 20px;
              position: relative;
            "
          >
            <p
              style="
                color: #4c5358;
                font-size: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 0px;
              "
            >
              NOVEL
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="9" lg="10" class="divClass_2_padre">
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Ketamine
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 12px"
            />
            <div class="div_no_data" :style="{ padding: '9px 60px 8px 61px' }">
              NO DATA
            </div>
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-left: 12px"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              Modafinil/Armodafinil
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              :style="{
                flex: 'none',
                'margin-right': '24px',
                display: validationImgBD1,
              }"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              :style="{
                flex: 'none',
                'margin-right': '24px',
                display: validationImgBD1,
              }"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              :style="{
                flex: 'none',
                'margin-right': '24px',
                display: validationImgBD1,
              }"
            />
            <div
              class="div_no_data"
              :style="{
                padding: '9px 60px 8px 61px',
                display: validationDivData,
              }"
            >
              NO DATA
            </div>
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          padding-bottom: 0;
          padding-top: 0;
        "
      >
        <div>
          <div
            class="divClass_1"
            style="
              background-color: white;
              width: 298px;
              height: 74px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 20px;
              position: relative;
            "
          >
            <p
              style="
                color: #4c5358;
                font-size: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 0px;
              "
            >
              stimulation
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="9" lg="10" class="divClass_2_padre">
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              ECT
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation2.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/validation1.svg`)"
              width="32"
              height="32"
              style="flex: none; margin-right: 24px"
            />
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none"
            />
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              TMS
            </p>
          </div>

          <div
            class="div-interior-center"
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <v-img
              :src="require(`../assets/less1.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-right: 12px"
            />
            <div class="div_no_data" :style="{ padding: '9px 60px 8px 61px' }">
              NO DATA
            </div>
            <v-img
              :src="require(`../assets/more.svg`)"
              width="24"
              height="24"
              style="flex: none; margin-left: 12px"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="2"
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          padding-bottom: 0;
          padding-top: 0;
        "
      >
        <div>
          <div
            class="divClass_1"
            style="
              background-color: white;
              width: 298px;
              height: 111px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 20px;
              position: relative;
            "
          >
            <p
              style="
                color: #4c5358;
                font-size: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 0px;
              "
            >
              Psychotherapy
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="9" lg="10" class="divClass_2_padre">
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              CBT
            </p>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <div
              class="div-button-not-aplicable"
              style="
                border-radius: 32px;
                background: #f2f2f2;
                color: #65594a;
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0.25px;
                padding: 9px 90px 8px 91px;
                height: 32px;
              "
            >
              NOT APLICABLE
            </div>
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              FFT
            </p>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <div
              class="div-button-not-aplicable"
              style="
                border-radius: 32px;
                background: #f2f2f2;
                color: #65594a;
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0.25px;
                padding: 9px 90px 8px 91px;
                height: 32px;
              "
            >
              NOT APLICABLE
            </div>
          </div>
        </div>
        <div class="divClass_2">
          <div style="width: 50%">
            <p
              style="padding: 6px 0 6px 89px; margin-bottom: 0px"
              class="p-col9"
            >
              IPSRT
            </p>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: center;
            "
          >
            <div
              class="div-button-not-aplicable"
              style="
                border-radius: 32px;
                background: #f2f2f2;
                color: #65594a;
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0.25px;
                padding: 9px 90px 8px 91px;
                height: 32px;
              "
            >
              NOT APLICABLE
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.divClass_1 {
  border-radius: 0 20px 20px 0;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
  border: 1px solid #dccec1;
}

.parrafo-g {
  color: #4c5358;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.p-col9 {
  color: #65594a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  border-bottom: 1px solid #f2f2f2;
  /* MOMENTANEO*/
}

.divClass_2 {
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  height: 37px;
}

.title-card-1 {
  color: #4c5358;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-transform: uppercase;
}

.buttonTab2_css {
  background: #bdbdbd;
  width: 50%;
  margin-right: 4px;
  border-radius: 10px 10px 0px 0px;
  color: #faf4f2 !important;
  text-align: center;
  font-family: Poppins;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
}

.info_bd2 {
  flex: none;
}

.div_no_data {
  border-radius: 32px;
  background: #f2f2f2;
  color: #65594a;
  text-align: center;
  font-family: Poppins;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.25px;
}
</style>

<script>
import MenuIcono from "./repetitive/MenuIcono.vue";
import TitleGeneral from "./repetitive/TitleGeneral.vue";

export default {
  name: "ClinicalEvidence",
  components: {
    MenuIcono,
    TitleGeneral,
  },
  data: () => ({
    colorButtonTab: "#131B70",
    colorButtonTab1: "#BDBDBD",
    dialogTableKey: false,
    btnVerified: true,
    btnVerified1: false,
    validationImgInfo: "none",
    validationImgBD1: "block",
    validationDivData: "none",
    nameImageCircle: "validation2",
    nameImageLess: "less1",
    itemsClinicalEvidence: [
      { title: "Antipsychotics" },
      { title: "Mood stabilizers" },
      { title: "Antidepressants" },
      { title: "NOVEL" },
      { title: "stimulation" },
      {
        title: "Psychotherapy",
        itemsEvidence: [
          {
            titleEvidence: "CBT",
            imagenBD1: "",
            imagenBD2: "",
            imagenBD3: "",
          },
        ],
      },
    ],
  }),
  methods: {
    activeColorButtonTab() {
      this.btnVerified = !this.btnVerified;

      if (this.btnVerified == true) {
        this.colorButtonTab = "#131B70";
        this.colorButtonTab1 = "#BDBDBD";
        this.btnVerified1 = false;

        /* LOS QUE DESAPARECEN*/
        this.nameImageCircle = "validation2";
        this.nameImageLess = "less1";
        this.validationDivData = "none";
        this.validationImgBD1 = "block";
      }
    },
    activeColorButtonTab1() {
      this.btnVerified1 = !this.btnVerified1;

      if (this.btnVerified1 == true) {
        this.colorButtonTab = "#BDBDBD";
        this.colorButtonTab1 = "#131B70";
        this.btnVerified = false;

        /* LOS QUE DESAPARECEN*/
        this.nameImageCircle = "validation1";
        this.nameImageLess = "less";
        this.validationDivData = "block";
        this.validationImgBD1 = "none";
      }
    },
  },
};
</script>
