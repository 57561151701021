<template>
  <v-app id="inspire">
    <v-navigation-drawer
      style="background: #131b70; width: 366px"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      permanent
    >
      <v-list dense style="position: relative">
        <template>
          <v-list-item
            v-for="(item, index) in items"
            @click="menuActionClick(item.action, index)"
            :key="index"
            :to="{ name: item.action }"
            :style="{ color: '#DCCEC1' }"
            ref="estadoActivo"
            v-if="item.action == 'home'"
          >
            <v-list-item-action>
              <v-img
                :src="
                  require(`./assets/${item.icon}${adicionalCambio_home}.svg`)
                "
                alt="IconHome"
                width="40"
                height="40"
              />
            </v-list-item-action>
            <v-list-item-title class="nav-title">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in items"
            @click="menuActionClick(item.action, index)"
            :key="index"
            :to="{ name: item.action }"
            style="color: #dccec1"
            v-if="item.action == 'interventions'"
          >
            <v-list-item-action>
              <v-img
                :src="
                  require(`./assets/${item.icon}${adicionalCambio_interventions}.svg`)
                "
                alt="IconHome"
                width="40"
                height="40"
              />
            </v-list-item-action>
            <v-list-item-title class="nav-title">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in items"
            @click="menuActionClick(item.action, index)"
            :key="index"
            :to="{ name: item.action }"
            :style="{
              color: '#DCCEC1',
              background: colorStatusSelected,
              display: colorStatusSelected,
            }"
            :class="[classActiveOutcomes]"
            v-if="item.action == 'outcomes'"
          >
            <v-list-item-action>
              <v-img
                :src="
                  require(`./assets/${item.icon}${adicionalCambio_outcomes}.svg`)
                "
                alt="IconHome"
                width="40"
                height="40"
              />
            </v-list-item-action>
            <v-list-item-title class="nav-title">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in items"
            @click="menuActionClick(item.action, index)"
            :key="index"
            :to="{ name: item.action }"
            style="color: #dccec1"
            v-if="item.action == 'clinical-scenarios'"
          >
            <v-list-item-action>
              <v-img
                :src="
                  require(`./assets/${item.icon}${adicionalCambio_clinicalScenarios}.svg`)
                "
                alt="IconHome"
                width="40"
                height="40"
              />
            </v-list-item-action>
            <v-list-item-title class="nav-title">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in items"
            @click="menuActionClick(item.action, index)"
            :key="index"
            :to="{ name: item.action }"
            style="color: #dccec1"
            v-if="item.action == 'treatment-networks'"
          >
            <v-list-item-action>
              <v-img
                :src="
                  require(`./assets/${item.icon}${adicionalCambio_treatmentNetworks}.svg`)
                "
                alt="IconHome"
                width="40"
                height="40"
              />
            </v-list-item-action>
            <v-list-item-title class="nav-title">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in items"
            @click="menuActionClick(item.action, index)"
            :key="index"
            :to="{ name: item.action }"
            style="color: #dccec1"
            v-if="item.action == 'glossary'"
          >
            <v-list-item-action>
              <v-img
                :src="
                  require(`./assets/${item.icon}${adicionalCambio_glossary}.svg`)
                "
                alt="IconHome"
                width="40"
                height="40"
              />
            </v-list-item-action>
            <v-list-item-title class="nav-title">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </template>

        <v-menu
          v-model="menu"
          open-on-click
          :close-on-content-click="true"
          :nudge-width="200"
          offset-x
          :style="{ 'border-radius': '0 !important', left: '350px !important' }"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              style="position: absolute; top: 225px; left: 300px"
            >
              <v-img
                :src="
                  require(`./assets/${flechade}${adicionalCambio_outcomes}.svg`)
                "
                width="20"
                height="20"
              />
            </v-btn>
          </template>

          <v-card
            style="
              padding: 0;
              width: 365px;
              background-color: #131b70;
              color: #faf4f2;
              font-size: 14px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              border-radius: 0 !important;
            "
          >
            <v-list dense :style="{ padding: '0 !important' }">
              <template>
                <v-list-item
                  v-for="(outcome, indexo) in outcomes"
                  @click="menuActionClick(outcome.action, indexo)"
                  :key="indexo"
                  :to="{ name: outcome.action }"
                  style="color: #3e3731; padding: 0 0 0 45px"
                >
                  <v-list-item-action>
                    <v-img
                      :src="require(`./assets/outcomes_nav/${outcome.icon}`)"
                      alt="IconHome"
                      width="28"
                      height="28"
                    />
                  </v-list-item-action>
                  <v-list-item-title class="nav-title-outcomes">
                    {{ outcome.title }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-menu>
      </v-list>

      <div style="position: absolute; bottom: 32px; left: 93.5px">
        <v-btn
          icon
          v-bind="attrs1"
          v-on="on"
          :style="{ 'margin-right': '90px', display: validationButtonLeft }"
          @click="redirigirSiguienteIzquierda"
        >
          <v-img
            :src="require(`./assets/arrowleft.svg`)"
            width="40"
            height="40"
          />
        </v-btn>

        <v-btn
          icon
          v-bind="attrs2"
          v-on="on"
          :style="{
            'margin-left': validationMarginLeft,
            display: validationButtonRight,
          }"
          @click="redirigirSiguienteDerecha"
        >
          <v-img
            :src="require(`./assets/arrowright.svg`)"
            width="40"
            height="40"
          />
        </v-btn>
      </div>
    </v-navigation-drawer>
    <v-main style="padding: 0px 0px 0px 366px">
      <v-container class="fill-height" fluid>
        <v-slide-y-transition mode="out-in">
          <router-view />
        </v-slide-y-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
.v-list .v-list-item--active {
  background-color: #b88542 !important;
  color: #dccec1;
}

.v-list .v-list-item:first-child {
  border-top: 1px solid #455d89;
}

.v-list .v-list-item {
  border-bottom: 1px solid #455d89;
}

.v-list-item {
  padding: 10px 30px;
}

.nav-title {
  font-size: 20px !important;
  font-size: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.nav-title-outcomes {
  color: #dccec1;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.v-list {
  padding: 30px 0;
}

.container.fill-height {
  background: #f2f2f2;
  align-items: start;
}

.fill-height {
  padding: 30px 12px 12px 12px;
}

.menuable__content__active {
  border-radius: 0px;
  left: 366px !important;
  max-width: 366px;
}

.theme--light.v-list {
  background: #131b70;
}

.v-responsive {
  flex: initial;
}
</style>

<script>
export default {
  name: "App",
  data: () => ({
    drawer: null,
    flechade: "flechade",
    classActiveOutcomes: "",
    validationMarginLeft: "0",
    validationButtonLeft: "",
    validationButtonRight: "",
    colorStatusSelected: "#131B70",
    adicionalCambio_home: "",
    adicionalCambio_interventions: "",
    adicionalCambio_outcomes: "",
    adicionalCambio_clinicalScenarios: "",
    adicionalCambio_treatmentNetworks: "",
    adicionalCambio_glossary: "",
    //activeColorHome: '#131B70 !important',
    items: [
      {
        title: "Home",
        icon: "home",
        action: "home",
      },

      {
        title: "Interventions",
        icon: "intervention",
        action: "interventions",
      },

      {
        title: "Outcomes",
        icon: "outcomes",
        action: "outcomes",
      },

      {
        title: "Clinical Scenarios",
        icon: "clinical",
        action: "clinical-scenarios",
      },

      {
        title: "Treatment Networks",
        icon: "treatment",
        action: "treatment-networks",
      },

      // {
      //   title: "Glossary",
      //   icon: "glossary",
      //   action: "glossary",
      // },
    ],
    outcomes: [
      {
        title: "Clinical Evidence BDI and II",
        icon: "clinical.svg",
        action: "clinical-evidence",
      },

      {
        title: "Laboratory Monitoring",
        icon: "laboratory.svg",
        action: "laboratory-monitoring",
      },

      {
        title: "Warnings",
        icon: "warning.svg",
        action: "warning",
      },

      {
        title: "Weight Gain",
        icon: "weight.svg",
        action: "weight-again",
      },

      {
        title: "Sedation",
        icon: "sedation.svg",
        action: "sedation",
      },

      {
        title: "Sexual Dysfunction",
        icon: "sexual.svg",
        action: "sexual-dysfunction",
      },

      {
        title: "Tremors",
        icon: "tremor.svg",
        action: "tremor",
      },

      {
        title: "Cognition",
        icon: "cognition.svg",
        action: "cognition",
      },

      {
        title: "Perinatal Concerns",
        icon: "perinatal.svg",
        action: "perinatal-concern",
      },

      {
        title: "Manic Switch",
        icon: "manic.svg",
        action: "manic-switch",
      },

      {
        title: "Time-to-response",
        icon: "time.svg",
        action: "time-to-response",
      },

      {
        title: "Cost",
        icon: "cost.svg",
        action: "cost",
      },
    ],
    itemsBucle: [
      "",
      "interventions",
      "outcome",
      "clinical-evidence",
      "laboratory-monitoring",
      "warning",
      "weight-again",
      "sedation",
      "sexual-dysfunction",
      "tremor",
      "cognition",
      "perinatal-concern",
      "manic-switch",
      "time-to-response",
      "cost",
      "clinical-scenarios",
      "treatment-networks",
      // "glossary",
    ],
  }),
  methods: {
    getSession() {
      if (this.$route.path === "/") {
        this.$refs.estadoActivo[0].$el.classList.add("v-list-item--active");
        this.validationButtonRight = "";
        this.validationButtonLeft = "none";
        this.validationMarginLeft = "126px";
        this.adicionalCambio_home = "a";
        this.adicionalCambio_interventions = "";
        this.adicionalCambio_outcomes = "";
        this.adicionalCambio_clinicalScenarios = "";
        this.adicionalCambio_treatmentNetworks = "";
        this.adicionalCambio_glossary = "";
        this.colorStatusSelected = "#131B70";
      } else if (this.$route.path === "/interventions") {
        this.$refs.estadoActivo[0].$el.classList.remove("v-list-item--active");
        this.validationButtonRight = "";
        this.validationButtonLeft = "";
        this.validationMarginLeft = "0";
        this.adicionalCambio_interventions = "a";
        this.adicionalCambio_home = "";
        this.adicionalCambio_outcomes = "";
        this.adicionalCambio_clinicalScenarios = "";
        this.adicionalCambio_treatmentNetworks = "";
        this.adicionalCambio_glossary = "";
        this.colorStatusSelected = "#131B70";
      } else if (this.$route.path === "/outcome") {
        this.$refs.estadoActivo[0].$el.classList.remove("v-list-item--active");
        this.validationButtonRight = "";
        this.validationButtonLeft = "";
        this.validationMarginLeft = "0";
        this.adicionalCambio_outcomes = "a";
        this.adicionalCambio_home = "";
        this.adicionalCambio_interventions = "";
        this.adicionalCambio_clinicalScenarios = "";
        this.adicionalCambio_treatmentNetworks = "";
        this.adicionalCambio_glossary = "";
      } else if (this.$route.path === "/clinical-scenarios") {
        this.$refs.estadoActivo[0].$el.classList.remove("v-list-item--active");
        this.validationButtonRight = "";
        this.validationButtonLeft = "";
        this.validationMarginLeft = "0";
        this.adicionalCambio_clinicalScenarios = "a";
        this.adicionalCambio_home = "";
        this.adicionalCambio_interventions = "";
        this.adicionalCambio_outcomes = "";
        this.adicionalCambio_treatmentNetworks = "";
        this.adicionalCambio_glossary = "";
        this.colorStatusSelected = "#131B70";
      } else if (this.$route.path === "/treatment-networks") {
        this.$refs.estadoActivo[0].$el.classList.remove("v-list-item--active");
        this.validationButtonRight = "none";
        this.validationButtonLeft = "";
        this.validationMarginLeft = "0";
        this.adicionalCambio_treatmentNetworks = "a";
        this.adicionalCambio_home = "";
        this.adicionalCambio_interventions = "";
        this.adicionalCambio_outcomes = "";
        this.adicionalCambio_clinicalScenarios = "";
        this.adicionalCambio_glossary = "";
        this.colorStatusSelected = "#131B70";
        // } else if (this.$route.path === "/glossary") {
        //   this.$refs.estadoActivo[0].$el.classList.remove("v-list-item--active");
        //   this.validationButtonRight = "none";
        //   this.validationButtonLeft = "";
        //   this.validationMarginLeft = "0";
        //   this.adicionalCambio_glossary = "a";
        //   this.adicionalCambio_home = "";
        //   this.adicionalCambio_interventions = "";
        //   this.adicionalCambio_outcomes = "";
        //   this.adicionalCambio_clinicalScenarios = "";
        //   this.adicionalCambio_treatmentNetworks = "";
        //   this.colorStatusSelected = "#131B70";
      } else if (
        this.$route.path === "/clinical-evidence" ||
        this.$route.path === "/laboratory-monitoring" ||
        this.$route.path === "/warning" ||
        this.$route.path === "/weight-again" ||
        this.$route.path === "/sedation" ||
        this.$route.path === "/sexual-dysfunction" ||
        this.$route.path === "/tremor" ||
        this.$route.path === "/cognition" ||
        this.$route.path === "/perinatal-concern" ||
        this.$route.path === "/manic-switch" ||
        this.$route.path === "/time-to-response" ||
        this.$route.path === "/cost"
      ) {
        this.$refs.estadoActivo[0].$el.classList.remove("v-list-item--active");
        this.validationButtonRight = "";
        this.validationButtonLeft = "";
        this.validationMarginLeft = "0";
        this.colorStatusSelected = "#B88542";
        this.adicionalCambio_outcomes = "a";
        this.adicionalCambio_glossary = "";
        this.adicionalCambio_home = "";
        this.adicionalCambio_interventions = "";
        this.adicionalCambio_clinicalScenarios = "";
        this.adicionalCambio_treatmentNetworks = "";
      }
    },
    redirigirSiguienteDerecha() {
      let rutaActual = this.$route.path;
      let rutaSiguiente = this.itemsBucle;
      let countItemsBucle = this.itemsBucle.length;
      let routes = this.$router;
      let changeColorValue = "";

      this.itemsBucle.forEach(function (texto, index) {
        if (rutaActual == "/" + texto) {
          index = index + 1;

          if (index < countItemsBucle) {
            routes.push(rutaSiguiente[index]);
          }

          if (index >= 3 && index <= 14) {
            changeColorValue = "#B88542";
          } else {
            changeColorValue = "#131B70";
          }
        }
      });

      this.colorStatusSelected = changeColorValue;
      this.getSession();
    },

    redirigirSiguienteIzquierda() {
      let rutaActual = this.$route.path;
      let rutaSiguiente = this.itemsBucle;
      let routes = this.$router;
      let changeColorValue = "";

      this.itemsBucle.forEach(function (texto, index) {
        if (rutaActual == "/" + texto) {
          index = index - 1;

          if (index >= 0) {
            if (rutaSiguiente[index] == "") {
              routes.push("/");
            } else {
              routes.push(rutaSiguiente[index]);
            }
          }

          if (index >= 3 && index <= 14) {
            changeColorValue = "#B88542";
          } else {
            changeColorValue = "#131B70";
          }
        }
      });

      this.colorStatusSelected = changeColorValue;
      this.getSession();
    },
  },

  created: function () {
    this.getSession();
  },

  updated: function () {
    this.getSession();
  },
};
</script>
